<template>
  <div class="login">
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>Backstage - Login </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="form.username"
            :counter="20"
            :rules="usernameRules"
            label="Username"
            :disabled="loading"
            required
          ></v-text-field>

          <v-text-field
            v-model="form.password"
            :counter="20"
            :rules="passwordRules"
            label="Password"
            required
            type="password"
            :disabled="loading"
            @keydown.enter="validate()"
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            提交
          </v-btn>

          <v-btn color="error" class="mr-4" @click="reset"> 重置表单 </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-footer padless app>
      <v-col class="text-center" cols="12">
        <strong>Public - Guide ：</strong>
        <v-btn
          color="indigo"
          outlined
          small
          class="tools-base-btn-style"
          @click="$router.push('/tools/record')"
          >消息记录处理器</v-btn
        ><v-btn
          color="indigo"
          outlined
          small
          class="tools-base-btn-style"
          @click="$router.push('/tools/trpg/model')"
          >TRPG Model 编辑器</v-btn
        >

        <v-btn
          color="indigo"
          outlined
          small
          class="tools-base-btn-style"
          @click="$router.push('/tools/ninecard')"
          >FFXIV 九宫幻卡对局分析模拟器</v-btn
        >
      </v-col>
    </v-footer>
  </div>
</template>

<style>
.tools-base-btn-style {
  margin-left: 10px;
}
html {
  overflow-y: auto;
}
</style>

<script>
import Login from "./login";
export default Login;
</script>
