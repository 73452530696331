import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator';
import aes from 'crypto-js/aes';
import hex from 'crypto-js/enc-hex';
import sha256 from "crypto-js/sha256";
import Axios from "axios";


@Component({})
export default class Login extends Vue {

  loading = false;
  selection = 1;
  valid = false;
  cryptoKey = hex.parse('12223455125435063425124204575516');
  cryptoIv = hex.parse('05210352473562157534214512563228')

  form = {
    username: '',
    password: ''
  }

  requestData = [];

  usernameRules = [
    (v: any) => !!v || 'Username is required',
    (v: any) => (v && v.length <= 20) || 'Name must be less than 10 characters',
  ]

  passwordRules = [
    (v: any) => !!v || 'Password is required',
    (v: any) => (v && v.length <= 20) || 'Name must be less than 10 characters'
  ]

  validate() {

    if ((this.$refs as any).form.validate()) {
      //通过验证后提交数据
      this.loading = true;
      const token = sha256(this.form.username + '&' + this.form.password).toString();
      const cryptoJson = {
        token: token,
        timestamp: Date.parse(new Date().toString())
      }
      const ciphertext = aes.encrypt(JSON.stringify(cryptoJson), this.cryptoKey, { iv: this.cryptoIv });
      const _this = this;
      Axios({
        url: '/api/v1/authentication',
        method: 'put',
        headers: {
          'Authorization': ciphertext.toString()
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          //Axios.defaults.headers.common['Authorization'] = response.headers.authorization;
          localStorage.setItem("token", response.headers.authorization);
          console.log(Axios.defaults.headers.common['Authorization']);
          _this.$toast.success(response.data.message);
          _this.valid = false;
          _this.$router.push('/dashboard');
        } else {
          _this.$toast.error(response.data.message);
          _this.loading = false;
        }
      }).catch(function (err) {
        _this.$toast.error(err);
        _this.loading = false;
      });

    }
  }

  reset() {
    (this.$refs as any).form.reset();
  }


}